import React from 'react';
/**
 * Converts date from string to Date object,
 * returns date as DD/MM/YY and HH:MM:SS
 *
 */
export const formatDate = (datestring) => {
  const date = new Date(datestring);
  const ddmmyyyy = `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()}`;
  const hhmmdd = `${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;

  return (
    <React.Fragment>
      {ddmmyyyy} <br /> {hhmmdd}
    </React.Fragment>
  );
};
