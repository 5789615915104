import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { authContext } from '../../context/auth';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

import MiraklWebsiteDialog from '../../components/WebsiteDialog/MiraklWebsiteDialog';

const Mirakl = () => {
  const { api } = useContext(authContext);

  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);

  const [sites, setSites] = useState([]);
  const [dialogData, setDialogData] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  /**
   * Fetches the data to be displayed on first render.
   */
  useEffect(() => {
    fetchWebsites();
  }, [page]);

  /**
   * Fetches the websites and sets them in the state.
   *
   * @returns {Promise}
   */
  const fetchWebsites = () => {
    return api({
      method: 'get',
      url: '/mirakl/',
      params: { p: page },
    }).then((res) => {
      setSites(res.data.data);
      setPages(res.data.pages);
    });
  };

  /**
   * Deletes a website, using "url" as the key.
   *
   * @param {String} url
   * @returns {Promise}
   */
  const deleteWebsite = (id) => {
    return api({
      method: 'delete',
      url: `/mirakl/${id}`,
    }).then(() => {
      fetchWebsites();
      setDeleteDialog(false);
    });
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          border: '1px dashed grey',
          gap: 2,
        }}
      >
        <Typography variant="h4" sx={{ fontSize: 20, fontWeight: '700' }}>
          Inserisci un nuovo sito
        </Typography>
        <Button onClick={() => setDialogData(true)} variant="contained">
          Aggiungi
        </Button>
      </Box>
      <Box>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Url</TableCell>
              <TableCell align="center">Query</TableCell>
              <TableCell align="center">Prezzo</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sites?.map((row) => (
              <TableRow
                key={`site-${row.url}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row?.url}
                </TableCell>

                <TableCell align="center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100px',
                        width: '100px',
                        overflow: 'hidden',
                        wordBreak: 'break-all',
                      }}
                    >
                      <div style={{ height: 'fit-content', margin: 'auto' }}>
                        {row?.query}
                      </div>
                    </div>
                  </div>
                </TableCell>

                <TableCell align="center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100px',
                        width: '100px',
                        overflow: 'hidden',
                        wordBreak: 'break-all',
                      }}
                    >
                      <div style={{ height: 'fit-content', margin: 'auto' }}>
                        {row?.price}
                      </div>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  style={{ paddingTop: '20px', paddingBottom: '20px' }}
                  align="center"
                >
                  <Stack>
                    <Button
                      variant="contained"
                      onClick={() => setDialogData(row)}
                    >
                      Modifica
                    </Button>
                    <br />
                    <Button
                      onClick={() => setDeleteDialog(row?.id)}
                      variant="contained"
                      color="error"
                    >
                      Elimina
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={pages}
          size="small"
          page={page}
          onChange={(_, p) => setPage(p)}
        />
      </Box>
      <MiraklWebsiteDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
        callback={fetchWebsites}
      />

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => setDeleteDialog(false)}
        open={!!deleteDialog}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          Eliminare definitivamente?
        </DialogTitle>

        <Box
          sx={{
            display: 'flex',

            justifyContent: 'center',
            alignItems: 'center',
            p: '10px',
            gap: '10px',
          }}
        >
          <Button
            onClick={() => deleteWebsite(deleteDialog)}
            variant="contained"
            color="error"
          >
            Sì
          </Button>
          <Button onClick={() => setDeleteDialog(false)} variant="outlined">
            No
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default Mirakl;
