import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import Menu from './components/Menu';
import { AuthProvider } from './context/auth';

import Login from './pages/Login';
import Translation from './pages/Translation';

import Ean from './pages/Ean';
import Websites from './pages/Websites';
import Configs from './pages/Configs';
import Images from './pages/Images';
import Ai from './pages/Ai';

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getDrawerWidth = () => {
    return collapsed ? 59 : 240;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${getDrawerWidth()}px)` },
              ml: { sm: `${getDrawerWidth()}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {process.env.DEFAULT_TITLE}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: getDrawerWidth() }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              <Menu />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: getDrawerWidth(),
                },
              }}
              open
            >
              <Menu collapsed={collapsed} />
              <List style={{ position: 'absolute', bottom: 0 }}>
                <ListItem key="collapse" disablePadding>
                  <ListItemButton onClick={() => setCollapsed(!collapsed)}>
                    <ListItemIcon>
                      {collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${getDrawerWidth()}px)` },
            }}
          >
            <Toolbar />
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/translation" element={<Translation />} />
                <Route path="/ai" element={<Ai />} />
                <Route path="/ean" element={<Ean />} />
                <Route path="/websites" element={<Websites />} />
                <Route path="/images" element={<Images />} />
                <Route path="/configuration" element={<Configs />} />
              </Routes>
            </AuthProvider>
          </Box>
        </Box>
      </BrowserRouter>
    </div>
  );
}

export default App;
