import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../context/auth";
import Stack from "@mui/material/Stack";

const Login = () => {
  const navigate = useNavigate();
  const { login, token } = useContext(authContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (token) {
      navigate("/translation");
    }
  }, [token]);

  const handleLogin = () => {
    // TODO: Change me with data from form
    login(username, password)
      .then(() => {
        navigate("/translation");
      })
      .catch((e: any) => {
        setError(e.response.data.message);
      });
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5" component="div">
              Login
            </Typography>
            <TextField
              required
              label="Username"
              defaultValue=""
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              required
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" onClick={handleLogin}>
              Login
            </Button>
            {error && <Alert severity="error">{error}</Alert>}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Login;
