import { useState } from 'react';
import { TextField, MenuItem, Box } from '@mui/material';
import UploadDescriptions from '../../components/Ai/UploadDescriptions';
import UploadCategories from '../../components/Ai/UploadCategories';
import UploadSEO from '../../components/Ai/UploadSEO';
import UploadExtractions from '../../components/Ai/UploadExtractions';
import JobsTable from '../../components/Ai/JobsTable';
import { JOB_TYPES } from '../../components/Ai/aiConstants';

const options = [
  { value: JOB_TYPES.AI_DESCRIPTION, label: 'Descrizioni' },
  { value: JOB_TYPES.AI_CATEGORIZATION, label: 'Categorie' },
  { value: JOB_TYPES.AI_SEO, label: 'SEO' },
  { value: JOB_TYPES.AI_EXTRACTION, label: 'Estrazione informazioni' },
];

const Ai = () => {
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box>
        <TextField
          select
          value={selectedOption}
          onChange={handleSelectChange}
          variant="outlined"
          style={{ width: '240px' }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {selectedOption === JOB_TYPES.AI_DESCRIPTION && <UploadDescriptions />}
      {selectedOption === JOB_TYPES.AI_CATEGORIZATION && <UploadCategories />}
      {selectedOption === JOB_TYPES.AI_SEO && <UploadSEO />}
      {selectedOption === JOB_TYPES.AI_EXTRACTION && <UploadExtractions />}

      <JobsTable jobType={selectedOption} />
    </Box>
  );
};

export default Ai;
