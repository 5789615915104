import React from 'react';
import FileDownload from 'js-file-download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState, useRef } from 'react';
import { useContext } from 'react';
import { authContext } from '../../context/auth';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { formatDate } from '../../utils/date';

function Mirakl() {
  const { api } = useContext(authContext);

  const [ean, setEan] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [eanDialog, setEanDialog] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [sites, setSites] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState('');

  const fileInput = useRef();

  /**
   * Loads the data when:
   * 1. component is mounted
   * 2. page changes
   *
   */
  useEffect(() => {
    fetchList();
  }, [page, refresh]);

  // Remove feedback after 3 seconds
  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowAlert(null);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showAlert]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(!refresh);
      fetchList();
    }, 10000);

    return () => clearInterval(interval);
  });

  const fetchList = () => {
    api({
      method: 'get',
      url: '/job/mirakl',
      params: { p: page, t: 'scrape_mirakl' },
    }).then((res) => {
      setEan(res.data.data);
      setPages(res.data.pages);
      setSites(res.data.websites);
    });
  };

  /**
   * Sets the selected file when the file is chosen
   *
   * @param {Event} event
   */
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * Handles the upload by using the selected file,
   *  if no file selected shows an error toast.
   * @returns
   */
  const handleUpload = () => {
    if (selectedFile === null) {
      setShowAlert('error');
      return;
    }
    const data = new FormData();
    data.append('file', selectedFile);
    data.append('website', selectedWebsite);
    api({
      method: 'post',
      url: '/mirakl/upload',
      data,
    })
      .then(() => {
        setShowAlert('success');
        fetchList();
        setPage(1);
      })
      .catch(() => {
        setShowAlert('error');
      })
      .finally(() => {
        setSelectedFile(null);
        fileInput.current.value = null;
      });
  };

  /**
   * Downloads a file based on the job ID
   *
   * @param {Number} id
   */
  const handleDownload = (id) => {
    api({
      method: 'get',
      url: `/mirakl/${id}`,
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, `product-list-${id}.xlsx`);
      })
      .catch(() => {
        setShowAlert('error');
      });
  };

  /**
   * Downloads a file containing the products that failed the scraping process of a specific job
   *
   * @param {Number} id
   */
  const handleDownloadFailed = (id) => {
    api({
      method: 'get',
      url: `/mirakl/csv/${id}`,
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, `prodotti-falliti-${id}.csv`);
      })
      .catch(() => {
        setShowAlert('error');
      });
  };

  /**
   * Resume the job
   *
   * @param {Number} id
   */
  const resumeJob = (id) => {
    const data = { status: 'PENDING' };
    api({
      method: 'post',
      url: `/job/update/${id}`,
      data,
    })
      .then(() => {
        setRefresh(!refresh);
      })
      .catch(() => {
        setShowAlert('error');
      });
  };

  /**
   * Cancel the job
   *
   * @param {Number} id
   */
  const cancelDownload = (id) => {
    const data = { status: 'CANCELLED' };
    api({
      method: 'post',
      url: `/job/update/${id}`,
      data,
    })
      .then(() => {
        setRefresh(!refresh);
      })
      .catch(() => {
        setShowAlert('error');
      });
  };

  /**
   * Displays the correct action based on job status
   *
   */
  const lineActions = (row) => {
    switch (row.status) {
      case 'DONE':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <Button onClick={() => handleDownload(row.id)} variant="contained">
              Scarica
            </Button>
            <Button
              onClick={() => handleDownloadFailed(row.id)}
              variant="outlined"
            >
              Falliti
            </Button>
          </Box>
        );

      case 'RUNNING':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <CircularProgressWithLabel value={row.progress} />
            <Button onClick={() => handleDownload(row.id)} variant="contained">
              Parziale
            </Button>
            <Button onClick={() => cancelDownload(row.id)} variant="outlined">
              Annulla
            </Button>
          </Box>
        );

      case 'CANCELLED':
        return (
          <Button onClick={() => resumeJob(row.id)} variant="outlined">
            Riprendi
          </Button>
        );

      default:
        return (
          <React.Fragment>
            <CircularProgressWithLabel value={row.progress} />
            <Button onClick={() => cancelDownload(row.id)} variant="outlined">
              Annulla
            </Button>
          </React.Fragment>
        );
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          border: '1px dashed grey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ pt: 2, pb: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontSize: 20, fontWeight: '700' }}>
                Scegli il CSV con i codici prodotto da caricare{' '}
              </Typography>
              <small>
                Scarica file di <a href="/example/mirakl.csv">esempio</a>
              </small>
              <input
                type="file"
                name="file"
                ref={fileInput}
                onChange={changeHandler}
              />

              <FormControl sx={{ m: 1 }}>
                <InputLabel>Sito da analizzare</InputLabel>
                <Select
                  value={selectedWebsite}
                  onChange={(event) => setSelectedWebsite(event.target.value)}
                  input={<OutlinedInput label="Sito da analizzare" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      <Chip key={selected} label={selected} />
                    </Box>
                  )}
                >
                  {sites.map((site) => (
                    <MenuItem key={site.url} value={site.url}>
                      {site.url}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>

          <Button
            disabled={selectedFile && selectedWebsite ? false : true}
            style={{ margin: '10px' }}
            onClick={handleUpload}
            variant="contained"
          >
            Upload
          </Button>
        </Box>
        {showAlert && (
          <Alert severity={showAlert}>
            <strong>
              {showAlert === 'success'
                ? 'File caricato correttamente!'
                : 'Errore nel caricamento'}
            </strong>
          </Alert>
        )}
      </Box>

      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Orario inizio</TableCell>
            <TableCell align="center">Orario fine</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ean.map((row) => (
            <TableRow
              key={`job-${row.id}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.filename ? `${row.filename}` : '-'}
              </TableCell>

              <TableCell align="center">{row.status}</TableCell>
              <TableCell align="center">{formatDate(row.created_at)}</TableCell>
              <TableCell align="center">
                {row.completed_at ? formatDate(row.completed_at) : '-'}
              </TableCell>
              <TableCell align="center">{lineActions(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={pages}
        size="small"
        page={page}
        onChange={(_, p) => setPage(p)}
      />
    </div>
  );
}

export default Mirakl;
