export const models = ['gpt-3.5-turbo', 'gpt-3.5-turbo-16k', 'gpt-4'];
export const DEFAULT_TEMPERATURE = '0.3';
export const JOB_TYPES = {
  AI_SEO: 'ai_seo',
  AI_CATEGORIZATION: 'ai_categorization',
  AI_DESCRIPTION: 'ai_description',
  AI_EXTRACTION: 'ai_extraction',
};

export const LANGUAGES = [
  'Italian',
  'English',
  'French',
  'German',
  'Spanish',
  'Portuguese PT',
];
