import axios, { AxiosRequestConfig } from "axios";
import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface AuthContext {
  token?: string;
  login: Function;
  api: Function;
}

const defaultContext = {
  login: () => {},
  api: () => {}
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

export const authContext = createContext<AuthContext>(defaultContext);

function useAuth(): AuthContext {
  const navigate = useNavigate();
  const [token, setToken]: [any, any] = useState();

  useEffect(() => {
    setToken(window.localStorage.getItem('token') || '');
    if (!token) {
      navigate('/login');
    }
  }, [])

  return {
    token,
    async login(user: string, pass: string) {
      const res = await axios.post(`${API_BASE_URL}/auth/login`, { user, pass });
      window.localStorage.setItem('token', res.data.token);
      setToken(res.data.token);
    },
    api(options: AxiosRequestConfig<any>) {
      options.baseURL = API_BASE_URL;
      options.headers = { ...options.headers, 'Authorization': `Bearer ${token}` };
      return axios(options)
        .catch((err) => {
          if(err.response.status === 401) {
            window.localStorage.removeItem('token');
            setToken('');
            navigate('/login');
          }
          throw err;
        })
    }
  };
}

export function AuthProvider({ children }: any) {
  const auth = useAuth();

  // Only continue rendering if the token is initialized
  if (auth.token !== undefined) {
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
  }
  return <></>;
}