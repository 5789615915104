import React from 'react';
import FileDownload from 'js-file-download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState, useRef } from 'react';
import { useContext } from 'react';
import { authContext } from '../../context/auth';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import { formatDate } from '../../utils/date';

const languages = ['en', 'fr', 'de', 'es', 'pt-PT'];

function Translation() {
  const { api } = useContext(authContext);

  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [eanDialog, setEanDialog] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const fileInput = useRef();

  /**
   * Loads the data when:
   * 1. component is mounted
   * 2. page changes
   *
   */
  useEffect(() => {
    fetchList();
  }, [page, refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(!refresh);
      fetchList();
    }, 10000);

    return () => clearInterval(interval);
  });

  const fetchList = () => {
    api({
      method: 'get',
      url: '/job/',
      params: { p: page, t: 'translation' },
    }).then((res) => {
      setJobs(res.data.data);
      setPages(res.data.pages);
    });
  };

  const applyEanDialog = (data) => {
    try {
      setEanDialog(JSON.parse(data));
    } catch (e) {}
  };

  /**
   * Sets the selected file when the file is chosen
   *
   * @param {Event} event
   */
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * Handles the upload by using the selected file,
   *  if no file selected shows an error toast.
   * @returns
   */
  const handleUpload = () => {
    setShowAlert(false);
    const data = new FormData();
    data.append('languages', selectedLanguages);
    data.append('file', selectedFile);
    api({
      method: 'post',
      url: '/translation/upload',
      data,
    })
      .then((res) => {
        if (res.data.errors.length) {
          setShowAlert({
            severity: 'warning',
            message: 'File caricato con errori nelle linee:',
            errors: res.data.errors,
          });
        } else {
          setShowAlert({
            severity: 'success',
            message: 'File caricato correttamente!',
          });
        }
        fetchList();
        setPage(1);
      })
      .catch(() => {
        setShowAlert({ severity: 'error', message: 'Errore nel caricamento' });
      })
      .finally(() => {
        setSelectedFile(null);
        setSelectedLanguages([]);
        fileInput.current.value = null;
      });
  };

  /**
   * Downloads a file based on the job ID
   *
   * @param {Number} id
   */
  const handleDownload = (id) => {
    api({
      method: 'get',
      url: `/translation/${id}`,
      responseType: 'blob',
    }).then((response) => {
      FileDownload(response.data, `translation-list-${id}.xlsx`);
    });
  };

  /**
   * Cancel the job
   *
   * @param {Number} id
   */
  const cancelDownload = (id) => {
    const data = { status: 'CANCELLED' };
    api({
      method: 'post',
      url: `/job/update/${id}`,
      data,
    });
    setRefresh(!refresh);
  };

  /**
   * Displays the correct action based on job status
   *
   */
  const lineActions = (row) => {
    switch (row.status) {
      case 'DONE':
        return (
          <Button onClick={() => handleDownload(row.id)} variant="contained">
            Scarica
          </Button>
        );

      case 'CANCELLED':
        return;

      default:
        return (
          <React.Fragment>
            <CircularProgressWithLabel value={row.progress} />
            <Button onClick={() => cancelDownload(row.id)} variant="outlined">
              Annulla
            </Button>
          </React.Fragment>
        );
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          border: '1px dashed grey',
        }}
      >
        {' '}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ pt: 2, pb: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontSize: 20, fontWeight: '700' }}>
                Scegli il file XLSX con le traduzioni da eseguire{' '}
              </Typography>
              <small>
                Scarica file di <a href="/example/translation.xlsx">esempio</a>
              </small>
              <input
                type="file"
                name="file"
                ref={fileInput}
                onChange={changeHandler}
              />
              <FormControl sx={{ m: 1 }}>
                <InputLabel>Lingue Target</InputLabel>
                <Select
                  multiple
                  value={selectedLanguages}
                  onChange={(event) => setSelectedLanguages(event.target.value)}
                  input={<OutlinedInput label="Lingue Target" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {languages.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
          <Button
            disabled={selectedFile ? false : true}
            style={{ margin: '10px' }}
            onClick={handleUpload}
            variant="contained"
          >
            Upload
          </Button>
        </Box>
        {showAlert && (
          <Alert severity={showAlert.severity}>
            <strong>{showAlert.message}</strong>
            {showAlert.errors && (
              <ul>
                {showAlert.errors.map((line) => (
                  <li>{line}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}
      </Box>

      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="center">Ean</TableCell>
            <TableCell align="center">Lingue Target</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Orario inizio</TableCell>
            <TableCell align="center">Orario fine</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs &&
            jobs.map((row) => (
              <TableRow
                key={`job-${row.id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {row.filename ? `${row.filename}` : '-'}
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => applyEanDialog(row.data)}>Vedi</Button>
                </TableCell>
                <TableCell align="center">
                  {JSON.parse(row.data).languages.join(',')}
                </TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.completed_at ? formatDate(row.completed_at) : '-'}
                </TableCell>
                <TableCell align="center">{lineActions(row)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={pages}
        size="small"
        page={page}
        onChange={(_, p) => setPage(p)}
      />
      <Dialog onClose={() => setEanDialog(false)} open={!!eanDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>EAN</DialogTitle>
        <List sx={{ pt: 0, pb: 2 }}>
          {typeof eanDialog === 'object' &&
            eanDialog.eans.map((item) => {
              return <ListItem key={item}>{item}</ListItem>;
            })}
        </List>
      </Dialog>
    </div>
  );
}

export default Translation;
