import { useContext, useRef, useState } from 'react';
import { authContext } from '../../context/auth';
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Typography,
  OutlinedInput,
  Alert,
} from '@mui/material';

import { models, DEFAULT_TEMPERATURE, JOB_TYPES } from './aiConstants';

const JOB_TYPE = JOB_TYPES.AI_CATEGORIZATION;

function UploadCategories() {
  const { api } = useContext(authContext);
  const [selectedModel, setSelectedModel] = useState(models[0]);
  const [temperature, setTemperature] = useState(0.3);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [error, setError] = useState(false);

  const fileInput = useRef();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    setShowAlert(false);
    const data = new FormData();
    data.append('file', selectedFile);
    data.append('model', selectedModel);
    data.append('temperature', temperature || DEFAULT_TEMPERATURE);
    data.append('jobType', JOB_TYPE);

    api({
      method: 'post',
      url: '/ai/upload',
      data,
    })
      .then((res) => {
        setShowAlert({
          severity: 'success',
          message: 'File caricato correttamente!',
        });
        setSelectedFile(null);
        setSelectedModel(models[0]);
        fileInput.current.value = null;
      })
      .catch((e) => {
        setShowAlert({ severity: 'error', message: 'Errore nel caricamento' });
      });
  };

  const validateCreativity = (value) => {
    // Value between 0 and 2
    const pattern = /^([0-2](\.[0-9])?)?$/;
    return pattern.test(value);
  };

  const handleChangeTemperature = (event) => {
    const inputValue = event.target.value;
    setTemperature(inputValue);
    setError(!validateCreativity(inputValue));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
        border: '1px dashed grey',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ pt: 2, pb: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h4" sx={{ fontSize: 20, fontWeight: '700' }}>
              Scegli il file XLSX con i prodotti da categorizzare
            </Typography>
            <small>
              Scarica file di <a href="/example/categorization.xlsx">esempio</a>
            </small>
            <input
              type="file"
              name="file"
              ref={fileInput}
              onChange={changeHandler}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ m: 1 }}>
                <InputLabel>Modello</InputLabel>
                <Select
                  value={selectedModel}
                  onChange={(event) => setSelectedModel(event.target.value)}
                  input={<OutlinedInput label="Modello" />}
                  renderValue={(selected) => (
                    <Chip key={selected} label={selected} />
                  )}
                  sx={{ minWidth: '200px' }}
                >
                  {models.map((model) => (
                    <MenuItem key={model} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ p: 1 }}>
                <TextField
                  error={error}
                  placeholder="0.3"
                  label="Creatività"
                  InputLabelProps={{ shrink: true }}
                  value={temperature}
                  onChange={handleChangeTemperature}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
        <Button
          variant="contained"
          disabled={!selectedFile || error}
          onClick={handleUpload}
        >
          Upload
        </Button>
        {showAlert && (
          <Alert sx={{ margin: '20px 0' }} severity={showAlert.severity}>
            <strong>{showAlert.message}</strong>
            {showAlert.errors && (
              <ul>
                {showAlert.errors.map((line) => (
                  <li>{line}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}
      </Box>
    </Box>
  );
}

export default UploadCategories;
