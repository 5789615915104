import React, { useEffect, useState, useContext } from 'react';
import { authContext } from '../../context/auth';
import FileDownload from 'js-file-download';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
} from '@mui/material';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import { formatDate } from '../../utils/date';

function JobsTable({ jobType }) {
  const { api } = useContext(authContext);

  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    fetchList();
  }, [page, refresh, jobType]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(!refresh);
      fetchList();
    }, 10000);

    return () => clearInterval(interval);
  });

  const fetchList = () => {
    api({
      method: 'get',
      url: '/job/',
      params: { p: page, t: jobType },
    }).then((res) => {
      setJobs(res.data.data);
      setPages(res.data.pages);
    });
  };

  const handleDownload = (row) => {
    api({
      method: 'get',
      url: `/ai/${row.id}`,
      params: { t: jobType },
      responseType: 'blob',
    }).then((response) => {
      FileDownload(response.data, `ai-risultati-${row.filename}`);
    });
  };

  const cancelDownload = (id) => {
    const data = { status: 'CANCELLED' };
    api({
      method: 'post',
      url: `/job/update/${id}`,
      data,
    });
    setRefresh(!refresh);
  };

  const lineActions = (row) => {
    switch (row.status) {
      case 'DONE':
        return (
          <Button onClick={() => handleDownload(row)} variant="contained">
            Scarica
          </Button>
        );

      case 'CANCELLED':
        return;

      default:
        return (
          <React.Fragment>
            <CircularProgressWithLabel value={row.progress} />
            <Button onClick={() => cancelDownload(row.id)} variant="outlined">
              Annulla
            </Button>
          </React.Fragment>
        );
    }
  };

  return (
    <Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Orario inizio</TableCell>
            <TableCell align="center">Orario fine</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs &&
            jobs.map((row) => (
              <TableRow
                key={`job-${row.id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {row.filename ? `${row.filename}` : '-'}
                </TableCell>

                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.completed_at ? formatDate(row.completed_at) : '-'}
                </TableCell>
                <TableCell align="center">{lineActions(row)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={pages}
        size="small"
        page={page}
        onChange={(_, p) => setPage(p)}
      />
    </Box>
  );
}

export default JobsTable;
