import React from 'react';
import FileDownload from 'js-file-download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState, useRef } from 'react';
import { useContext } from 'react';
import { authContext } from '../../context/auth';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Alert from '@mui/material/Alert';

import Typography from '@mui/material/Typography';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import TextField from '@mui/material/TextField';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { formatDate } from '../../utils/date';

const methods = ['fit', 'cover', 'thumb'];
const extensions = ['png', 'jpeg'];
const defaultData = {
  method: 'cover',
  height: 150,
  width: 150,
  extension: 'png',
};

function Images() {
  const { api } = useContext(authContext);
  const [showAlert, setShowAlert] = useState(null);
  const fileInput = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(defaultData.method);
  const [selectedExtension, setSelectedExtension] = useState(
    defaultData.extension
  );
  const [formData, setFormData] = useState({ ...defaultData });
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const [validForm, setValidForm] = useState(true);

  /**
   * Checks if form is valid when formData changes:
   *
   */
  useEffect(() => {
    if (formData.height !== '' && formData.width !== '') {
      setValidForm(true);
      setShowAlert(false);
    } else {
      setValidForm(false);
      setShowAlert({
        severity: 'warning',
        message: 'Completa tutti i campi',
      });
    }
  }, [formData]);

  /**
   * Loads the data when:
   * 1. component is mounted
   * 2. page changes
   *
   */
  useEffect(() => {
    fetchList();
  }, [page, refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(!refresh);
      fetchList();
    }, 10000);

    return () => clearInterval(interval);
  });

  const fetchList = () => {
    api({
      method: 'get',
      url: '/job/',
      params: { p: page, t: 'resize_image' },
    }).then((res) => {
      setJobs(res.data.data);
      setPages(res.data.pages);
    });
  };

  /**
   * Handles the upload by using the selected file,
   *  if no file selected shows an error toast.
   * @returns
   */
  const handleUpload = () => {
    setShowAlert(false);
    const data = new FormData();
    data.append(
      'options',
      JSON.stringify({
        ...formData,
        method: selectedMethod,
        extension: selectedExtension,
      })
    );
    data.append('file', selectedFile);
    api({
      method: 'post',
      url: '/image/upload',
      data,
    })
      .then((res) => {
        if (res.data.errors.length) {
          setShowAlert({
            severity: 'warning',
            message: 'Errore nel caricamento del file',
          });
        } else {
          setShowAlert({
            severity: 'success',
            message: 'File caricato correttamente!',
          });
        }
        fetchList();
        setPage(1);
      })
      .catch(() => {
        setShowAlert({ severity: 'error', message: 'Errore nel caricamento' });
      })
      .finally(() => {
        setSelectedFile(null);
        fileInput.current.value = null;
      });
  };

  const handleChange = (e, property) => {
    const regex = /^[0-9\b]+$/;

    switch (property) {
      case 'height':
        if (
          (e.target.value === '' || regex.test(e.target.value)) &&
          e.target.value.length <= 4
        ) {
          setFormData({ ...formData, height: e.target.value });
        }

        break;

      case 'width':
        if (
          (e.target.value === '' || regex.test(e.target.value)) &&
          e.target.value.length <= 4
        ) {
          setFormData({ ...formData, width: e.target.value });
        }

        break;
      default:
    }
  };

  /**
   * Sets the selected file when the file is chosen
   *
   * @param {Event} event
   */
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * Downloads a file based on the job ID
   *
   * @param {Number} id
   */
  const handleDownload = (id) => {
    api({
      method: 'get',
      url: `/image/${id}`,
      responseType: 'blob',
    }).then((response) => {
      FileDownload(response.data, `image-list-${id}.xlsx`);
    });
  };

  /**
   * Cancel the job
   *
   * @param {Number} id
   */
  const cancelDownload = (id) => {
    const data = { status: 'CANCELLED' };
    api({
      method: 'post',
      url: `/job/update/${id}`,
      data,
    });
    setRefresh(!refresh);
  };

  /**
   * Displays the correct action based on job status
   *
   */
  const lineActions = (row) => {
    switch (row.status) {
      case 'DONE':
        return (
          <Button onClick={() => handleDownload(row.id)} variant="contained">
            Scarica
          </Button>
        );

      case 'CANCELLED':
        return;

      default:
        return (
          <React.Fragment>
            <CircularProgressWithLabel value={row.progress} />
            <Button onClick={() => cancelDownload(row.id)} variant="outlined">
              Annulla
            </Button>
          </React.Fragment>
        );
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          border: '1px dashed grey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ pt: 2, pb: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontSize: 20, fontWeight: '700' }}>
                Scegli il file XLSX con le immagini da editare
              </Typography>
              <input
                type="file"
                name="file"
                ref={fileInput}
                onChange={changeHandler}
              />
            </Stack>
          </Box>

          <Box sx={{ pt: 2, pb: 2 }}>
            <Stack spacing={2}>
              <Typography variant="4" sx={{ fontSize: 16, fontWeight: '700' }}>
                Opzioni
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <FormControl>
                  <Box>
                    <InputLabel>Metodo</InputLabel>
                    <Select
                      sx={{ width: '200px', height: '60px' }}
                      value={selectedMethod}
                      onChange={(event) => {
                        setSelectedMethod(event.target.value);
                      }}
                      input={<OutlinedInput label="Metodo" />}
                      renderValue={(selected) => (
                        <Chip key={selected} label={selected} />
                      )}
                    >
                      {methods.map((method) => (
                        <MenuItem key={method} value={method}>
                          {method}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </FormControl>
                <FormControl>
                  <Box>
                    <InputLabel>Estensione</InputLabel>
                    <Select
                      sx={{ width: '200px', height: '60px' }}
                      value={selectedExtension}
                      onChange={(event) => {
                        setSelectedExtension(event.target.value);
                      }}
                      input={<OutlinedInput label="Estensione" />}
                      renderValue={(selected) => (
                        <Chip key={selected} label={selected} />
                      )}
                    >
                      {extensions.map((extension) => (
                        <MenuItem key={extension} value={extension}>
                          {extension}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <TextField
                  value={formData.height}
                  margin="dense"
                  fullWidth
                  label="Altezza in px"
                  rows={1}
                  placeholder="150"
                  onChange={(e) => {
                    handleChange(e, 'height');
                  }}
                />

                <TextField
                  value={formData.width}
                  margin="dense"
                  fullWidth
                  label="Larghezza in px"
                  rows={1}
                  placeholder="150"
                  onChange={(e) => {
                    handleChange(e, 'width');
                  }}
                />
              </Box>
            </Stack>
          </Box>

          <Button
            disabled={selectedFile && validForm ? false : true}
            style={{ margin: '10px' }}
            onClick={handleUpload}
            variant="contained"
          >
            Upload
          </Button>
        </Box>
        {showAlert && (
          <Alert severity={showAlert.severity}>
            <strong>{showAlert.message}</strong>
          </Alert>
        )}
      </Box>

      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Orario inizio</TableCell>
            <TableCell align="center">Orario fine</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs &&
            jobs.map((row) => (
              <TableRow
                key={`job-${row.id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {row.filename ? `${row.filename}` : '-'}
                </TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.completed_at ? formatDate(row.completed_at) : '-'}
                </TableCell>
                <TableCell align="center">{lineActions(row)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={pages}
        size="small"
        page={page}
        onChange={(_, p) => setPage(p)}
      />
    </div>
  );
}

export default Images;
