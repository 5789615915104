import Divider from '@mui/material/Divider';
import WebIcon from '@mui/icons-material/Web';
import TranslateIcon from '@mui/icons-material/Translate';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PhotoIcon from '@mui/icons-material/Photo';
import BuildIcon from '@mui/icons-material/Build';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Link, useLocation } from 'react-router-dom';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const Menu = (props) => {
  const { collapsed } = props;
  const location = useLocation();
  return (
    <>
      <Toolbar style={{ padding: collapsed ? '10px' : '' }}>
        <img
          src={!collapsed ? '/logo.png' : '/logo.png'}
          height="46"
          alt="Logo"
        />
      </Toolbar>
      <Divider />
      <List>
        <ListItem key="translations" disablePadding>
          <ListItemButton
            component={Link}
            to="/translation"
            selected={location.pathname === '/translation'}
          >
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Traduzioni" />}
          </ListItemButton>
        </ListItem>

        <ListItem key="ean" disablePadding>
          <ListItemButton
            component={Link}
            to="/ean"
            selected={location.pathname === '/ean'}
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Ean" />}
          </ListItemButton>
        </ListItem>

        <ListItem key="ai" disablePadding>
          <ListItemButton
            component={Link}
            to="/ai"
            selected={location.pathname === '/ai'}
          >
            <ListItemIcon>
              <SmartToyIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="AI" />}
          </ListItemButton>
        </ListItem>

        <ListItem key="websites" disablePadding>
          <ListItemButton
            component={Link}
            to="/websites"
            selected={location.pathname === '/websites'}
          >
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Siti" />}
          </ListItemButton>
        </ListItem>
        <ListItem key="images" disablePadding>
          <ListItemButton
            component={Link}
            to="/images"
            selected={location.pathname === '/images'}
          >
            <ListItemIcon>
              <PhotoIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Immagini" />}
          </ListItemButton>
        </ListItem>
        <ListItem key="configs" disablePadding>
          <ListItemButton
            component={Link}
            to="/configuration"
            selected={location.pathname === '/configuration'}
          >
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Configurazioni" />}
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default Menu;
