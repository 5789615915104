import { useState } from 'react';
import { TextField, MenuItem, Box } from '@mui/material';
import { WEBSITES_TYPES } from '../../constants/website';
import Mirakl from './Mirakl';
import Ean from './Ean';

const options = [
  { value: WEBSITES_TYPES.TRADITIONAL_WEBSITES, label: 'Siti classici' },
  { value: WEBSITES_TYPES.MIRAKL_WEBSITES, label: 'Siti Mirakl' },
];

const ScraperPage = () => {
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box>
        <TextField
          select
          value={selectedOption}
          onChange={handleSelectChange}
          variant="outlined"
          style={{ width: '240px' }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {selectedOption === WEBSITES_TYPES.TRADITIONAL_WEBSITES && <Ean />}
      {selectedOption === WEBSITES_TYPES.MIRAKL_WEBSITES && <Mirakl />}
    </Box>
  );
};

export default ScraperPage;
