import { useEffect, useState, useContext } from 'react';
import { authContext } from '../../context/auth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

const defaultData = {
  url: '',
  query: '',
  price: '',
};

const MiraklWebsiteDialog = (props) => {
  const { dialogData, setDialogData, callback } = props;
  const { api } = useContext(authContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [urlValid, setUrlValid] = useState(true);
  const [formData, setFormData] = useState({});

  /**
   * Copies the data inside the local state.
   */
  useEffect(() => {
    setFormData({ ...dialogData });
  }, [dialogData]);

  /**
   * When dialog data does not contain the existing website data,
   *  adds a new website, otherwise edits the existing one
   *
   * @returns {Promise}
   */
  const handleSubmit = () => {
    if (dialogData === true) {
      return addWebsite();
    }
    return editWebsite();
  };

  /**
   * Checks if the URL is valid.
   *
   * @param {String | undefined} url
   * @returns {Boolean}
   */
  const validateUrl = (url) => {
    return !!url;
  };

  /**
   * Adds a new website and closes the dialog.
   *  In case of error does not close the dialog but shows an error message.
   *
   * @returns {Promise}
   */
  const addWebsite = () => {
    setAlertMessage(false);
    if (!validateUrl(formData.url)) {
      setAlertMessage('Inserire un URL');
      return;
    }
    const data = { ...defaultData, ...formData };
    return api({
      method: 'post',
      url: '/mirakl/',
      data,
    })
      .then(() => {
        callback();
        setDialogData(false);
      })
      .catch(() => {
        setAlertMessage("Errore nell'aggiunta del sito");
      });
  };

  /**
   * Edits an existing website and closes the dialog.
   *  In case of error does not close the dialog but shows an error message.
   *
   * @returns {Promise}
   */
  const editWebsite = () => {
    const data = { ...defaultData, ...formData };
    return api({
      method: 'put',
      url: `/mirakl/${dialogData.id}`,
      data,
    })
      .then(() => {
        callback();
        setDialogData(false);
      })
      .catch(() => {
        setAlertMessage('Errore nella modifica del sito');
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={() => setDialogData(false)}
      open={!!dialogData}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        {dialogData === true ? 'Aggiungi sito' : dialogData.url}
      </DialogTitle>
      <DialogContent>
        {dialogData === true && (
          <TextField
            required
            error={!urlValid}
            margin="dense"
            fullWidth
            label="Url"
            multiline
            rows={8}
            defaultValue={formData.url}
            placeholder="Inserisci Url"
            onChange={(e) => {
              setUrlValid(validateUrl(e.target.value));
              setFormData({ ...formData, url: e.target.value });
            }}
          />
        )}
        <TextField
          margin="dense"
          fullWidth
          label="Query"
          multiline
          rows={8}
          defaultValue={formData.query}
          placeholder="Inserisci la Query relativa all'Url"
          onChange={(e) => {
            setFormData({ ...formData, query: e.target.value });
          }}
        />

        <TextField
          margin="dense"
          fullWidth
          label="Prezzo"
          multiline
          rows={8}
          defaultValue={formData.price}
          placeholder="Inserisci selettore Prezzo"
          onChange={(e) => {
            setFormData({ ...formData, price: e.target.value });
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: '10px',
            gap: '10px',
          }}
        >
          {alertMessage && (
            <Alert severity={'error'}>
              <strong>{alertMessage}</strong>
            </Alert>
          )}

          <Button
            onClick={handleSubmit}
            sx={{ width: 100 }}
            variant="contained"
          >
            {dialogData === true ? 'Aggiungi' : 'Aggiorna'}
          </Button>
          <Button
            onClick={() => {
              setDialogData(false);
            }}
            sx={{ width: 100 }}
            variant="outlined"
          >
            Annulla
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MiraklWebsiteDialog;
