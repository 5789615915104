import React from "react";

import { useEffect, useState, useContext } from "react";
import { authContext } from "../../context/auth";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

const defaultData = {
  value: "",
};

const ConfigDialog = (props) => {
  const { dialogData, setDialogData, callback } = props;
  const { api } = useContext(authContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [keyValid, setKeyValid] = useState(true);
  const [formData, setFormData] = useState({});

  /**
   * Copies the data inside the local state.
   */
  useEffect(() => {
    setFormData({ ...dialogData });
  }, [dialogData]);

  /**
   * When dialog data does not contain the existing configuration data,
   *  adds a new configuration, otherwise edits the existing one
   *
   * @returns {Promise}
   */
  const handleSubmit = () => {
    if (dialogData === true) {
      return addConfig();
    }
    return editConfig();
  };

  /**
   * Checks if the Key is valid.
   *
   * @param {String | undefined} key
   * @returns {Boolean}
   */
  const validateKey = (key) => {
    return !!key;
  };

  /**
   * Adds a new configuration and closes the dialog.
   *  In case of error does not close the dialog but shows an error message.
   *
   * @returns {Promise}
   */
  const addConfig = () => {
    setAlertMessage(false);
    if (!validateKey(formData.key)) {
      setAlertMessage("Inserire una chiave");
      return;
    }
    const data = { ...defaultData, ...formData };
    return api({
      method: "post",
      url: "/config/",
      data,
    })
      .then(() => {
        callback();
        setDialogData(false);
      })
      .catch(() => {
        setAlertMessage("Errore nell'aggiunta della configurazione");
      });
  };

  /**
   * Edits an existing configuration and closes the dialog.
   *  In case of error does not close the dialog but shows an error message.
   *
   * @returns {Promise}
   */
  const editConfig = () => {
    const data = { ...defaultData, ...formData };
    return api({
      method: "put",
      url: `/config/${dialogData.key}`,
      data,
    })
      .then(() => {
        callback();
        setDialogData(false);
      })
      .catch(() => {
        setAlertMessage("Errore nella modifica della configurazione");
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={() => setDialogData(false)}
      open={!!dialogData}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {dialogData === true ? "Aggiungi configurazione" : dialogData.key}
      </DialogTitle>
      <DialogContent>
        {dialogData === true && (
          <TextField
            required
            error={!keyValid}
            margin="dense"
            fullWidth
            label="Chiave"
            multiline
            rows={8}
            defaultValue={formData.key}
            placeholder="Inserisci chiave"
            onChange={(e) => {
              setKeyValid(validateKey(e.target.value));
              setFormData({ ...formData, key: e.target.value });
            }}
          />
        )}
        <TextField
          margin="dense"
          fullWidth
          label="Valore"
          multiline
          rows={8}
          defaultValue={formData.value}
          placeholder="Inserisci valore"
          onChange={(e) => {
            setFormData({ ...formData, value: e.target.value });
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: "10px",
            gap: "10px",
          }}
        >
          {alertMessage && (
            <Alert severity={"error"}>
              <strong>{alertMessage}</strong>
            </Alert>
          )}

          <Button
            onClick={handleSubmit}
            sx={{ width: 100 }}
            variant="contained"
          >
            {dialogData === true ? "Aggiungi" : "Aggiorna"}
          </Button>
          <Button
            onClick={() => {
              setDialogData(false);
            }}
            sx={{ width: 100 }}
            variant="outlined"
          >
            Annulla
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfigDialog;
